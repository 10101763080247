const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig = {
    auth: {
        clientId: process.env.AUTH_CLIENT_ID,
        authority: process.env.AUTH_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/auth`,
        postLogoutRedirectUri: `${process.env.AUTH_AUTHORITY}/oauth2/logout`
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: isIE || isEdge || isFirefox, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["user.read", "openid", "profile", "offline_access"] // `api://${process.env.AUTH_CLIENT_ID}/.default`
};

  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphApiV1Root: "https://graph.microsoft.com/v1.0"
  };