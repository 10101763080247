import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReitDetails from "../../components/reits/ReitDetails";
import * as actions from "../../actions/periodActions";
import * as commonActions from "../../actions/commonActions";
import * as chartOfAccountsActions from '../../actions/chartOfAccountsActions';
import * as reitActions from "../../actions/reitActions";
import { withRouter } from "../../common/withRouter";
import * as propertyActions from "../../actions/propertyActions";
import * as checklistActions from "../../actions/checklistActions";
import * as tbActions from "../../actions/trialBalanceActions";
import period from "../../reducers/periodReducer";
import * as servicesActions from "../../actions/servicesActions";
import * as serviceConstants from '../../constants/services';
import * as testSnapshotActions from "../../actions/testSnapshotActions";

/**
 * ReitPage container component
 * @extends {React.Component}
 */
export class ReitPage extends React.Component {
    /**
          * Creates a new PsqChecklistPage
          * @constructor
          * @param {Object} props The component properties
          * @param {Object} context The component context
          */
    constructor(props, context) {
        super(props, context);

        this.state = {
            activePeriodId: -1,
            showServicesModal: false,
            showNewPeriodModal: false,
            selectedCreateType: "Default",
            selectedQuarter: "Quarter 2",
            selectedYear: "2010",
            showClosePeriodModal: false,
            showOpenPeriodModal: false,
            activePeriod: null,
            tbMode: 1,
            showModeModal: false,
            chartExistsForClient: false,
            trialBalances: [],
            hasIncompleteChecklists: false,
            hideTbModeLink: false,
            currentReit: {},
            hideTbModeLinkList: [],
            trialBalancesList: [],
            hideTestSnapshotLinkList: [],
            hasIncompleteChecklistsList: []
        };
        this.setActivePeriodId = this.setActivePeriodId.bind(this);
        this.toggleShowServicesModal = this.toggleShowServicesModal.bind(this);
        this.toggleShowNewPeriodModal = this.toggleShowNewPeriodModal.bind(this);
        this.handleCreateNewPeriod = this.handleCreateNewPeriod.bind(this);
        this.handleQuarterSelection = this.handleQuarterSelection.bind(this);
        this.handleYearSelection = this.handleYearSelection.bind(this);
        this.toggleShowClosePeriodModal = this.toggleShowClosePeriodModal.bind(this);
        this.handleClosePeriod = this.handleClosePeriod.bind(this);
        this.handleOpenPeriod = this.handleOpenPeriod.bind(this);
        this.toggleShowOpenPeriodModal = this.toggleShowOpenPeriodModal.bind(this);
        this.toggleShowModeModal = this.toggleShowModeModal.bind(this);
        this.handleCloseModeModal = this.handleCloseModeModal.bind(this);
        this.handleSubmitTbMode = this.handleSubmitTbMode.bind(this);
        this.populateTrialBalancesList = this.populateTrialBalancesList.bind(this);
        this.populateHideTbModeLinkList = this.populateHideTbModeLinkList.bind(this);
        this.populateHideTestSnapshotLinkList = this.populateHideTestSnapshotLinkList.bind(this);
        this.populateHasIncompleteChecklistsList = this.populateHasIncompleteChecklistsList.bind(this);
        this.handleDownloadCurrentTestSnapshot = this.handleDownloadCurrentTestSnapshot.bind(this);
        this.handleDownloadTbTemplates = this.handleDownloadTbTemplates.bind(this);
        this.handleDownloadMappingTemplatePackage = this.handleDownloadMappingTemplatePackage.bind(this);
    }

    componentDidMount() {
        this.props.reitActions.fetchReit(this.props.reitId); 

        //Get charts based on Client
        if (this.props && this.props.clientId > 0) {
            this.props.chartOfAccountsActions.fetchChartsByClientId(this.props.clientId).then((charts) => {
                if (charts && charts.length > 0 ) {
                    this.setState({ chartExistsForClient: true });                    
                }
            });
        }

        this.setState({ selectedQuarter: this.getCurrentQuarterValue(), selectedYear: this.getCurrentYearValue() });

        // This part of the code is needed in REPORT PERIOD page to populate the trial balances list, hide tb mode link list, hide test snapshot link list, and has incomplete checklists list
        this.props.reit.periods?.map((period) => {
            this.populateTrialBalancesList(period);
            this.populateHideTbModeLinkList(period);
            this.populateHideTestSnapshotLinkList(period);
            this.populateHasIncompleteChecklistsList(period);
        });
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.period !== this.props.period && this.props.period.trialBalanceModeID) {
            let periodFromDb = Object.assign({}, this.props.period);
            periodFromDb.trialBalanceMode = periodFromDb.trialBalanceModeID.toString();
            this.setState({ activePeriod: periodFromDb });
        }

        // This part of the code is needed in REPORT PERIOD page to populate the trial balances list, hide tb mode link list, hide test snapshot link list, and has incomplete checklists list
        this.props.reit.periods?.map((period) => {
            if (prevProps.reit.periods !== this.props.reit.periods) {
                this.populateTrialBalancesList(period);
                this.populateHideTbModeLinkList(period);
                this.populateHideTestSnapshotLinkList(period);
                this.populateHasIncompleteChecklistsList(period);
            }
        });
    }

    populateTrialBalancesList(period) {
        tbActions.getTrialBalancesByPeriod(period.reportPeriodID, 'adjusted', 'consolidated').then((tbs) => {
            this.setState(prevState => {
                const trialBalancesList = Array.isArray(prevState.trialBalancesList) ? prevState.trialBalancesList : [];
                // Create the new period object
                const newPeriod = {
                    reportPeriodId: period.reportPeriodID,
                    consolidatedTrialBalance: tbs.find(x => x.trialBalancePurposeID && x.trialBalancePurposeID === 3)
                };
                // Check if the period is already in the list
                const periodExists = trialBalancesList.some(item => item.reportPeriodId === newPeriod.reportPeriodId);
                // If the period does not exist, add it to the list
                if (!periodExists) {
                    return { trialBalancesList: [...trialBalancesList, newPeriod] };
                }
                // If the period exists, return the state unchanged
                return prevState;
            });
        });
    }

    populateHideTbModeLinkList(period) {
        propertyActions.fastFetchPropertiesByPeriod(period.reportPeriodID).then((properties) => {
            this.setState(prevState => {
                const hideTbModeLinkList = Array.isArray(prevState.hideTbModeLinkList) ? prevState.hideTbModeLinkList : [];
                // Create the new period object
                const newPeriod = {
                    reportPeriodId: period.reportPeriodID,
                    hideTbModeLink: properties && properties.length > 1
                };
                // Check if the period is already in the list
                const periodExists = hideTbModeLinkList.some(item => item.reportPeriodId === newPeriod.reportPeriodId);
                // If the period does not exist, add it to the list
                if (!periodExists) {
                    return { hideTbModeLinkList: [...hideTbModeLinkList, newPeriod] };
                }
                // If the period exists, return the state unchanged
                return prevState;
            });
        });
    }

    populateHideTestSnapshotLinkList(period) {
        servicesActions.fetchServicesByPeriodWithoutDispatch(period.reportPeriodID).then((services) => {
            this.setState(prevState => {
                const hideTestSnapshotLinkObj = Array.isArray(prevState.hideTestSnapshotLinkList) ? prevState.hideTestSnapshotLinkList : [];
                // Create the new period object
                const newPeriod = {
                    reportPeriodId: period.reportPeriodID,
                    hideTestSnapshotLink: Array.isArray(services) && services.some(x => x === serviceConstants.ASSET_TEST_SERVICE || x === serviceConstants.INCOME_TEST_SERVICE || x === serviceConstants.DISTRIBUTION_TEST_SERVICE) ? false : true
                };
                // Check if the period is already in the list
                const periodExists = hideTestSnapshotLinkObj.some(item => item.reportPeriodId === newPeriod.reportPeriodId);
                // If the period does not exist, add it to the list
                if (!periodExists) {
                    return { hideTestSnapshotLinkList: [...hideTestSnapshotLinkObj, newPeriod] };
                }
                // If the period exists, return the state unchanged
                return prevState;
            });
        });
    }

    populateHasIncompleteChecklistsList(period) {
        checklistActions.fetchHasIncompleteCheckistByPeriod(period.reportPeriodID).then((data) => {
            this.setState(prevState => {
                const hasIncompleteChecklistsObj = Array.isArray(prevState.hasIncompleteChecklistsList) ? prevState.hasIncompleteChecklistsList : [];
    
                // Create the new period object
                const newPeriod = {
                    reportPeriodId: period.reportPeriodID,
                    hasIncompleteChecklists: data
                };
                // Check if the period is already in the list
                const periodExists = hasIncompleteChecklistsObj.some(item => item.reportPeriodId === newPeriod.reportPeriodId);
                // If the period does not exist, add it to the list
                if (!periodExists) {
                    return { hasIncompleteChecklistsList: [...hasIncompleteChecklistsObj, newPeriod] };
                }
                // If the period exists, return the state unchanged
                return prevState;
            });
        });
    }

    setActivePeriodId(periodId) {
        this.setState({ activePeriodId: periodId, showServicesModal: !this.state.showServicesModal },
            () => {
                if (periodId) {
                    this.props.actions.fetchPeriodById(periodId);
                }
            });
    }

    toggleShowServicesModal() {
        this.setState({ showServicesModal: !this.state.showServicesModal });
    }

    toggleShowClosePeriodModal(periodId) {
        this.setState({ showClosePeriodModal: !this.state.showClosePeriodModal });
        if (periodId) {
            this.setState({ activePeriodId: periodId });
        }
    }

    toggleShowOpenPeriodModal(periodId) {
        this.setState({ showOpenPeriodModal: !this.state.showOpenPeriodModal });
        if (periodId) {
            this.setState({ activePeriodId: periodId });
        }
    }

    toggleShowNewPeriodModal() {
        this.props.commonActions.clearErrorResponse();
        this.setState({ showNewPeriodModal: !this.state.showNewPeriodModal });
    }

    handleQuarterSelection(selectedQuarter) {
        this.setState({ selectedQuarter });
    }

    handleYearSelection(selectedYear) {
        this.setState({ selectedYear });
    }

    handleCreateNewPeriod(e) {
        if (e && e.target.value !== "CANCEL") {
            let quarter = 1;
            switch (this.state.selectedQuarter) {
            case "Quarter 2":
                quarter = 2;
                break;
            case "Quarter 3":
                quarter = 3;
                break;
            case "Quarter 4":
                quarter = 4;
                break;
            default:
            }
            this.props.actions.createPeriod(this.props.reit.reitid, quarter,  this.state.selectedYear, true);
        }

        this.setState({ showNewPeriodModal: !this.state.showNewPeriodModal });
    }

    handleClosePeriod(periodId, currentStatus) {
        this.setState({ activePeriodId: periodId, showClosePeriodModal: !this.state.showClosePeriodModal }, this.setCloseStatus(this.state.activePeriodId, currentStatus));
    }

    handleOpenPeriod(periodId, currentStatus) {
        this.setState({ activePeriodId: periodId, showOpenPeriodModal: !this.state.showOpenPeriodModal }, this.setOpenStatus(this.state.activePeriodId, currentStatus));
    }

    handleSubmitTbMode(values) {
        this.setTrialBalanceMode(values.reportPeriodStatusDescription, values.trialBalanceMode);
    }

    getCurrentQuarterValue() {
        const currentYear = new Date();
        const currentMonth = currentYear.getMonth();
        let currentQuarter = "Quarter 1";
        switch (currentMonth) {
        case 0:
        case 1:
        case 2:
            currentQuarter = "Quarter 1";
            break;
        case 3:
        case 4:
        case 5:
            currentQuarter = "Quarter 2";
            break;
        case 6:
        case 7:
        case 8:
            currentQuarter = "Quarter 3";
            break;
        case 9:
        case 10:
        case 11:
            currentQuarter = "Quarter 4";
            break;
        }
        return currentQuarter;
    }

    getCurrentYearValue() {
        const currentYear = new Date();
        return currentYear.getFullYear().toString();
    }

    setCloseStatus(periodId, currentStatus) {
        let newStatus = "TestingClosed";
        switch (currentStatus) {
        case "Closed to Testing":
            newStatus = "ReportingClosed";
            break;
        case "Closed to Reporting":
            newStatus = "Complete";
            break;
        }

        this.props.actions.savePeriod(this.state.activePeriodId, newStatus, true);
    }

    setOpenStatus(periodId, currentStatus) {
        let newStatus = "Complete";
        switch (currentStatus) {
        case "Closed to Testing":
            newStatus = "InProgress";
            break;
        case "Closed to Reporting":
            newStatus = "TestingClosed";
            break;
        case "Complete":
            newStatus = "ReportingClosed";
            break;
        }

        this.props.actions.savePeriod(this.state.activePeriodId, newStatus, true);
    }

    setTrialBalanceMode(status, trialBalanceMode) {
        if(status === "In Progress" || status === "Not Started") {
            this.props.actions.savePeriod(this.state.activePeriodId, "InProgress", true, trialBalanceMode).then(() => {
                this.props.reitActions.fetchReit(this.props.reitId);
            });
            this.handleCloseModeModal();
        }
    }

    toggleShowModeModal(periodId) {
        this.setState({ showModeModal: !this.state.showModeModal });
        if (periodId) {
            this.setState({ activePeriodId: periodId });
            this.props.actions.fetchPeriodById(periodId);
        }
    }

    handleCloseModeModal() {
        this.setState({ showModeModal: !this.state.showModeModal });
    }

    handleDownloadCurrentTestSnapshot(fileName, reportPeriodID) {
        this.props.commonActions.beginTask();
        return testSnapshotActions.downloadCurrentTestSnapshot(reportPeriodID, fileName)
            .then((blob) => {
                this.props.commonActions.endTask();
                return blob;
            });
    }

     handleDownloadTbTemplates(clientId, periodId) {
        return this.props.tbActions.downloadTbTemplates(clientId, false, periodId).then((blob) => {           
            return blob;
        });
    }

    handleDownloadMappingTemplatePackage(periodId) {
        this.props.commonActions.beginTask();
        return chartOfAccountsActions.downloadMappingTemplatePackage(periodId).then((blob) => {
            this.props.commonActions.endTask();
            return blob;
        });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (<ReitDetails clientId={this.props.clientId} reit={this.props.reit} activePeriodId={this.state.activePeriodId} handleActivePeriodIdChanged={this.setActivePeriodId} showServicesModal={this.state.showServicesModal} toggleShowServicesModal={this.toggleShowServicesModal} showNewPeriodModal={this.state.showNewPeriodModal} toggleShowNewPeriodModal={this.toggleShowNewPeriodModal} handleCreateNewPeriod={this.handleCreateNewPeriod} createTypes={actions.createTypes} showClosePeriodModal={this.state.showClosePeriodModal} toggleShowClosePeriodModal={this.toggleShowClosePeriodModal} handleClosePeriod={this.handleClosePeriod} handleQuarterSelection={this.handleQuarterSelection} handleYearSelection={this.handleYearSelection} selectedQuarter={this.state.selectedQuarter} selectedYear={this.state.selectedYear} handleOpenPeriod={this.handleOpenPeriod} showOpenPeriodModal={this.state.showOpenPeriodModal} toggleShowOpenPeriodModal={this.toggleShowOpenPeriodModal} tbMode={this.state.tbMode} toggleShowModeModal={this.toggleShowModeModal} showModeModal={this.state.showModeModal} handleCloseModeModal={this.handleCloseModeModal} handleSubmitTbMode={this.handleSubmitTbMode} 
            activePeriod={this.state.activePeriod} chartExistsForClient={this.state.chartExistsForClient} clientIsAudit={this.props.clientIsAudit}
            currentUserAuthorizations={this.props.currentUserAuthorizations} trialBalances={this.state.trialBalances} 
            hideTbModeLinkList={this.state.hideTbModeLinkList} trialBalancesList={this.state.trialBalancesList} hideTestSnapshotLinkList={this.state.hideTestSnapshotLinkList} hasIncompleteChecklistsList={this.state.hasIncompleteChecklistsList} 
            handleDownloadCurrentTestSnapshot={this.handleDownloadCurrentTestSnapshot} handleDownloadTbTemplates={this.handleDownloadTbTemplates} handleDownloadMappingTemplatePackage={this.handleDownloadMappingTemplatePackage}/>
        );
    }
}

ReitPage.propTypes = {
    clientId: PropTypes.number,
    reit: PropTypes.object,
    reitId: PropTypes.number.isRequired,
    period: PropTypes.object.isRequired,
    reitActions: PropTypes.object.isRequired,
    chartOfAccountsActions: PropTypes.object.isRequired,
    commonActions: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: Number.parseInt(ownProps.router.params.clientId),
        reitId: Number.parseInt(ownProps.router.params.reitId),
        reit: state.reit,
        period: state.period,
        clientIsAudit: state.client && state.client.isAudit,
        currentUserAuthorizations: state.currentUserAuthorizations,
        servicesInState: state.services,
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch),
        chartOfAccountsActions: bindActionCreators(chartOfAccountsActions, dispatch),
        tbActions: bindActionCreators(tbActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReitPage));