import React from 'react';
import { useNavigate, useLocation, useParams, useMatches, Outlet } from 'react-router-dom';

function CustomRouterProvider() {
  const router = {
    navigate: useNavigate(),
    location: useLocation(),
    params: useParams(),
    matches: useMatches()
  };

  // Expose the router context to the window object, as it is used by the CustomNavigationClient
  window.routerContext = router;
  
  return (
    <Outlet />
  );
}

export default CustomRouterProvider;