import React from 'react';
import FormattedCurrency from "./../../shared/FormattedCurrency";
import { FormattedNumber } from "react-intl";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

export default (props) => {
  if (props.fieldName == 'reitOwnedSharesValue') {
    return (
       <FormattedCurrency value={props.node.data.reitOwnedSharesValue} currency="USD" />
    );
  }

  if (props.fieldName == 'allSharesFromIssuerValue') {
    return (
      props.node.data.isTenPercentValueTestDisabled === true 
      ? "N/A" 
      : <FormattedCurrency value={props.node.data.allSharesFromIssuerValue} currency="USD" />
    );
  }

  if (props.fieldName == 'percentOfVote') {
    return (
      <FormattedNumber value={props.node.data.percentOfVote / 100} style="percent" minimumFractionDigits={4} />

    );
  }

  if (props.fieldName === 'actions') {
    return (
      props.canEdit ? (
        <div className="justify-content-end align-items-center mt-2">
          <MotifIconButton aria-label="Edit this Security Snapshot" type="button"
           onClick={() => props.handleEditButtonClick(props.node.data)}>
            <MotifIcon iconFunction={IconoirEditPencil} size='24' fill='none' title="Edit this Security Snapshot" />
          </MotifIconButton>
          <MotifIconButton aria-label="Delete this Security Snapshot" type="button"
            onClick={() => props.handleDeleteButtonClick(props.node.data)}>
            <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title="Delete this Security Snapshot" />
          </MotifIconButton>
        </div>
      ) : (
        <div className="justify-content-end align-items-center mt-2" >
          <MotifIconButton aria-label="Can't edit Security Snapshot - Testing Closed" type="button" disabled>
            <MotifIcon iconFunction={IconoirEditPencil} size='24' fill='none' aria-label="Can't edit Security Snapshot - Testing Closed" />
          </MotifIconButton>
          <MotifIconButton aria-label="Can't delete Security Snapshot - Testing Closed" type="button" disabled>
            <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' aria-label="Can't delete Security Snapshot - Testing Closed" />
          </MotifIconButton>
        </div>
      )
    );
  }
}
