import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import { Button, Icon } from "semantic-ui-react";
import ShortDate from "../shared/ShortDate";
import FormattedCurrency from "../shared/FormattedCurrency";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirEditPencil } from '@ey-xd/motif-icon';

const MappingsListRow = ({ mapping, handleEditButtonClick, tbMode, canEdit }) => {
    let className = "";
    if (mapping.isAPropertyMapping && tbMode !== 2) {
        className = "propertyMappingRow";
    }

    const getDate = (datetime) => {
        let date = new Date(datetime);
        /* Date converted to MM/DD/YYYY format */
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    const onEditButtonClick = () => {
        return handleEditButtonClick(mapping.clientAccountMappingID, mapping.isAPropertyMapping);
    };

    return (
        <tr className={className}>
            <td>{mapping.clientAccountCode}</td>
            <td>{mapping.clientAccountDescription}</td>
            <td>{mapping.clientAccountCategoryDescription}</td>
            <td>{mapping.reitTestingAttributeCode} - {mapping.reitTestingAttributeDescription}</td>
            <td>{mapping.subAttribute}</td>
            <td>
                <FormattedCurrency
                        value={mapping.balance}
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0} />
            </td>
            <td>{getDate(mapping.lastUpdatedDateTime)}</td>
            <td className="tcenter">
                <MotifIconButton aria-label={canEdit ? "Edit this Mapping" : "Can't edit this Mapping"} type="button"
                    onClick={canEdit && onEditButtonClick} disabled={!canEdit}> 
                    <MotifIcon iconFunction={IconoirEditPencil} size='24' fill='none' title={canEdit ? "Edit this Mapping" : ""} />
                </MotifIconButton>
            </td>
        </tr>
    );
};

MappingsListRow.propTypes = {
    mapping: PropTypes.object.isRequired,
    handleEditButtonClick: PropTypes.func.isRequired,
    tbMode: PropTypes.number,
    canEdit: PropTypes.bool
};

export default MappingsListRow;