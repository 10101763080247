/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifIcon,
} from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';
const InvalidEmailModal = ({
  showInvalidEmailModal,
  deleteInvalidEmail,
  headerText
}) => {
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Invalid Email modal',
    ariaLabel: 'Close Invalid Email modal',
    dataTestid: 'close',
  };

  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showInvalidEmailModal}
        onClose={deleteInvalidEmail}
        data-testid="InvalidEmailModal"
      >
        <MotifModalHeader
          data-testid="h3InvalidEmail"
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
          <span className="ml-2">{headerText}</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <span
            className="checklistActionMsg"
            data-testid="spanInvalidEmailInfo"
          >
           Please do not enter EY email addresses as the EY employees will receive email based on their notification preference settings.
          </span>
          <MotifModalFooter className="justify-content-center">
            <MotifButton
              variant="primary"
              className="center"
              data-testid="btnOk"
              type="button"
              onClick={deleteInvalidEmail}
            >
              OK
            </MotifButton>
       
          </MotifModalFooter>
        </MotifModalBody>
      </MotifModal>
    </React.Fragment>
  );
};

InvalidEmailModal.propTypes = {
  showInvalidEmailModal: PropTypes.bool,
  deleteInvalidEmail: PropTypes.func
};

export default InvalidEmailModal;
