import React, { useState } from 'react';
import { PropTypes } from "prop-types";
import { MotifButton } from '@ey-xd/motif-react';
import ClientAuthList from "../../components/auth/ClientAuthList";


const DataStewardList = ({
    handleGridChanges, handleEditButtonClick, handleDeleteButtonClick, toggleShowEditClientAuthModal, auths, auth,
    showEditClientAuthModal, handleEditClientAuthModalAction, isAuthorizedUserSearchBoxVisible, clientAuthFormValues,
    handleDailyNewsletter, receiveDailyNewsletter, handleWeeklyNewsletter, receiveWeeklyNewsletter, handleRealTimeNotification,
    receiveRealTimeNotification, receiveRealTimeNotificationForNewPsqArqcSent, handleInternalReviewNotification,clientId,
    handleAddClientAuthModalAction, showAddClientAuthModal, toggleShowAddClientAuthModal, handleAddClientAuthModalCancelAction,
    handleNewButtonClick, myEyError, validatingUser, handleOnRoleTypeChange, addAuthMessageText,handleGoToClientOverview,
    handleAddClientAuthModalValidateAction, validateDomainWithRole, isDataSteward, handleDataSteward,dataStewardCheckboxDisplay,dataStewardValue,showDSError,
    buttonVarient
}) => {
    return (
        <>
            <ClientAuthList
                auths={auths}
                auth={auth}
                handleGridChanges={handleGridChanges}
                handleEditButtonClick={handleEditButtonClick}
                showEditClientAuthModal={showEditClientAuthModal}
                handleEditClientAuthModalAction={handleEditClientAuthModalAction}
                toggleShowEditClientAuthModal={toggleShowEditClientAuthModal}
                isAuthorizedUserSearchBoxVisible={isAuthorizedUserSearchBoxVisible}
                clientAuthFormValues={clientAuthFormValues}
                handleDailyNewsletter={handleDailyNewsletter}
                receiveDailyNewsletter={receiveDailyNewsletter}
                handleWeeklyNewsletter={handleWeeklyNewsletter}
                receiveWeeklyNewsletter={receiveWeeklyNewsletter}
                handleRealTimeNotification={handleRealTimeNotification}
                receiveRealTimeNotification={receiveRealTimeNotification}
                receiveRealTimeNotificationForNewPsqArqcSent={receiveRealTimeNotificationForNewPsqArqcSent}
                handleInternalReviewNotification={handleInternalReviewNotification}
                handleDeleteButtonClick={handleDeleteButtonClick}
                showAddClientAuthModal={showAddClientAuthModal}
                toggleShowAddClientAuthModal={toggleShowAddClientAuthModal}
                handleAddClientAuthModalCancelAction={handleAddClientAuthModalCancelAction}
                addAuthMessageText={addAuthMessageText}
                handleAddClientAuthModalValidateAction={handleAddClientAuthModalValidateAction}
                validateDomainWithRole={validateDomainWithRole}
                validatingUser={validatingUser}
                myEyError={myEyError}
                handleOnRoleTypeChange={handleOnRoleTypeChange}
                handleNewButtonClick={handleNewButtonClick}
                handleAddClientAuthModalAction={handleAddClientAuthModalAction}
                isDataSteward={isDataSteward}
                handleDataSteward={handleDataSteward}
                dataStewardCheckboxDisplay={dataStewardCheckboxDisplay}
                showDSError={showDSError}
                handleGoToClientOverview={handleGoToClientOverview}
                clientId = {clientId}
                dataStewardValue = {dataStewardValue}
                buttonVarient = {buttonVarient}
            />
        </>
    );
};

DataStewardList.propTypes = {
    gridCurrentState: PropTypes.arrayOf(PropTypes.shape({
        checklistSelection: PropTypes.bool
    })),
    toggleShowEditClientAuthModal: PropTypes.func,
    showEditClientAuthModal: PropTypes.bool,
    handleEditClientAuthModalAction: PropTypes.func,
    clientAuthFormValues: PropTypes.object,
    handleDailyNewsletter: PropTypes.func,
    receiveDailyNewsletter: PropTypes.bool,
    handleWeeklyNewsletter: PropTypes.func,
    receiveWeeklyNewsletter: PropTypes.bool,
    handleRealTimeNotification: PropTypes.func,
    receiveRealTimeNotification: PropTypes.bool,
    receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
    handleInternalReviewNotification: PropTypes.func,
    auth: PropTypes.object,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    showAddClientAuthModal: PropTypes.bool,
    toggleShowAddClientAuthModal: PropTypes.func,
    addAuthMessageText: PropTypes.string,
    handleAddClientAuthModalValidateAction: PropTypes.func,
    validatingUser: PropTypes.bool,
    myEyError: PropTypes.string,
    handleNewButtonClick: PropTypes.func,
    handleAddClientAuthModalAction: PropTypes.func,
    isDataSteward: PropTypes.bool,
    dataStewardValue: PropTypes.bool,
    handleDataSteward: PropTypes.func,
    handleGoToClientOverview: PropTypes.func,
    clientId: PropTypes.number,
};

export default DataStewardList;
