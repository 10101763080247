import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubmissionError, reset, getFormValues } from "redux-form";
import * as authActions from "../../actions/authActions";
import * as actions from "../../actions/trialBalanceActions";
import * as reitActions from "../../actions/reitActions";
import * as propertyActions from "../../actions/propertyActions";
import * as chartAssignmentActions from "../../actions/chartOfAccountsManagerActions";
import * as PeriodActions from "../../actions/periodActions";
import * as lookupTypeActions from '../../actions/lookupTypeActions';
import * as actionHelpers from "../../scripts/actionHelpers";
import * as commonActions from "../../actions/commonActions";
import MappingsList from "../../components/tb/MappingsList";
import * as formHelpers from "../../scripts/formHelpers";
import { store }  from "../../store/configureStore";
import { MotifOption } from '@ey-xd/motif-react';
import { withRouter } from "../../common/withRouter";

/**
 * MappingsPage container component
 * @extends {React.Component}
 */
export class MappingsPage extends React.Component {

    /**
    * Creates a new MappingsPage
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.state = {
            mappingSearchText: "",
            showForm: false,
            formMapping: {
                "clientAccountCode": "",
                "clientAccountDescription": "",
                "clientAccountMappingId": null,
                "reitTestingAttributeCode": "",
                "reitTestingAttributeDescription": "",
                "periodId": 0,
                "propertyId": null,
            },
            formPurpose: "",
            isAPropertyMapping: false,
            showSuccess: null,
            canEdit: true,
            hideSuccessMessage: false,
            periodMappingCount: 0,
            tbMappings: [],
            tbMappingsBalances: [],
            selectedTbName: "",
            selectedTbId: 0,
            selectedTbPctOwnership: 100.0000,
            confirmAction: null,
            submittingMapping: false,
            tbMode: 1,
            reit: {},
            loading: false,
            showSyncREITTestingAttributesModal: false,
            syncInitialValue: { "synchType": "1" },
            chartMappings: [],
            chartName: ""
        };
        this.closeForm = this.closeForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleDismissSuccessMessage = this.handleDismissSuccessMessage.bind(this);
        this.trialBalancePurposes = this.trialBalancePurposes.bind(this);
        this.handleTrialBalancePurposeDropdownSelected = this.handleTrialBalancePurposeDropdownSelected.bind(this);
        this.handleDownloadAll = this.handleDownloadAll.bind(this);
        this.handleDeleteAll = this.handleDeleteAll.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.toggleShowSyncREITTestingAttributesModal = this.toggleShowSyncREITTestingAttributesModal.bind(this);
        this.handleSyncREITTestsingAttributeAction = this.handleSyncREITTestsingAttributeAction.bind(this);

    }

    /**
 * Invoked immediately after a component mounts
 */
    componentDidMount() {
        // Check if state.period is null, if it is populate period
        if (!this.props.period) {
            this.props.periodActions.fetchPeriodById(this.props.periodId);
        }

        // Fetch REIT Testing attribute lookup data
        this.props.lookupTypeActions.fetchChartOfAccountTypes();

        this.props.propertyActions.fetchPropertiesByPeriod(this.props.periodId).then(() => {
            if (this.props.period.trialBalanceModeID === 2) {
                // If consolidated TB mode, then fetch first property mappings and balances
                this.props.actions.getMappingsByPeriod(this.props.periodId, this.props.properties[0].propertyID, true);
            } else {
                // If not consolidated, initially fetch the REIT level data
                this.props.actions.getMappingsByPeriod(this.props.periodId, null, true);
            }
        });

        //Get Chart assignments/mappings based on Report period id
        this.props.chartAssignmentActions.fetchChartAssignmentsByReportPeriodId(this.props.periodId).then(() => {

            reitActions.fetchReitByPeriodId(this.props.periodId).then((reit) => {

                this.setState({ reit: reit, selectedTbName: reit.reitName + " - " + reit.reitTrackingID });

                //Get chart name based on REIT Id
                let chartDetails = this.props.chartMappings.find(m => m.reitid === reit.reitid);
                if (chartDetails) {
                    this.setState({ chartName: chartDetails.chartName });
                }
                else {
                    this.setState({ chartName: '' });
                }
            });

        });
    }

    /**
     * Invoked before a mounted component receives new props.
     * @param {Object} nextProps The periodMappings that the component is receiving
     */
    componentDidUpdate(prevProps) {
        const nextProps = this.props;

        if (nextProps.periodId !== prevProps.periodId && nextProps.periodId > 0) {
            this.props.actions.fetchPropertiesByPeriod(nextProps.periodId);
        }

        if (nextProps.period !== prevProps.period) {
            this.setCanEdit(nextProps.period);
            if (nextProps.period && nextProps.period.trialBalanceModeID === 2) {
                this.setState({ tbMode: 2, syncInitialValue: { "synchType": "2" } });
            } else {
                this.setState({ tbMode: 1, syncInitialValue: { "synchType": "1" } });
            }
        }

        if (nextProps.periodMappings !== prevProps.periodMappings) {
            this.setState({ periodMappingCount: nextProps.periodMappings.length },
                () => this.clearAndSetFilter()
            );
        }

        if (nextProps.periodMappingsBalances != prevProps.periodMappingsBalances) {
            this.setState({ tbMappingsBalances: Object.assign([], nextProps.periodMappingsBalances) }, () => this.clearAndSetFilter());
        }

        if (nextProps.mapping !== prevProps.mapping) {
            let mapping = {
                "clientAccountCode": nextProps.mapping.clientAccountCode,
                "clientAccountDescription": nextProps.mapping.clientAccountDescription,
                "clientAccountMappingId": nextProps.mapping.clientAccountMappingID,
                "reitTestingAttributeCode": nextProps.mapping.reitTestingAttributeCode,
                "reitTestingAttributeDescription": nextProps.mapping.reitTestingAttributeDescription,
                "periodId": nextProps.mapping.reportPeriodID,
                "propertyId": nextProps.mapping.propertyID,
            };
            this.setState({ formMapping: Object.assign({}, nextProps.mapping) });
            this.setState({ showForm: true, formPurpose: "EDIT", showSuccess: null });
        }

        if (nextProps.chartMappings != prevProps.chartMappings) {
            this.setState({ chartMappings: Object.assign({}, nextProps.chartMappings) });
        }
    }
    

    handleDismissErrorMessage() {
        this.props.commonActions.clearFormErrors('mapping');
    }

    handleDismissSuccessMessage() {
        this.setState({ hideSuccessMessage: true });
    }

    setCanEdit(period) {
        const isEnabled = PeriodActions.isTestingEnabled(period);
        this.setState({ canEdit: isEnabled });
    }

    submitForm(values) {
        // Hide modal and show spinner
        this.setState({ submittingMapping: true });
        const mapping = {
            "clientAccountMappingID": values.clientAccountMappingID,
            "reitTestingAttributeID": values.reitTestingAttribute && values.reitTestingAttribute,
            "reportPeriodID": this.props.periodId,
            "subAttribute" :parseInt(values.subAttribute),
        };

        return actions.updateMapping(mapping).then(data => {
            if (actionHelpers.isErrorResponse(data)) {
                // Hide spinner and show modal
                this.setState({ submittingMapping: false });
                formHelpers.generateSubmissionError(data);
                return;
            }
            if (this.props.period.trialBalanceModeID === 2) {
                // If consolidated TB mode, then fetch first property mappings and balances
                this.props.actions.getMappingsByPeriod(this.props.periodId, this.props.properties[0].propertyID, true);
            } else {
                this.props.actions.getMappingsByPeriod(this.props.periodId, this.state.selectedTbId, true);
            }
            this.closeForm();
        }).catch(error => {
            if (error instanceof SubmissionError) {
                throw error;
            }

            // Hide spinner and show modal
            this.setState({ submittingMapping: false });
            formHelpers.generateSubmissionError();
        });
    }

    showForm() {
        this.setState({ showForm: true, formPurpose: "", showSuccess: null });
    }

    closeForm() {
        this.setState({ showForm: false, showSuccess: null, submittingMapping: false });
    }

    handleEditButtonClick(id, isAPropertyMapping) {
        this.props.actions.getMappingById(id);
        this.setState({ isAPropertyMapping: isAPropertyMapping, submittingMapping: false });
    }  

    trialBalancePurposes() {
        let items = [];
        if (this.state.tbMode !== 2) {
            items.push(<MotifOption key={0} value={"0"}>REIT</MotifOption>);
        }
        if (this.props.properties.length > 0) {
            for (let i = 0; i < this.props.properties.length; i++) {
                items.push(<MotifOption key={i + 1} value={this.props.properties[i].propertyID.toString()}>{this.props.properties[i].propertyName}</MotifOption>);
            }
        }
        return items;
    }

    // Sets selectedTbName and selectedTbId
    handleTrialBalancePurposeDropdownSelected(e) {
        let value = e;

        if (value < 1) {
            this.props.actions.getMappingsByPeriod(this.props.periodId, null, true);

            this.setState({ selectedTbName: this.state.reit.reitName + " - " + this.state.reit.reitTrackingID, selectedTbId: 0, selectedTbPctOwnership: 100.0000 }, () => this.clearAndSetFilter());

            //Get chart name based on REIT Id
            let chartDetails = this.props.chartMappings.find(m => m.reitid === this.state.reit.reitid);
            if (chartDetails) {
                this.setState({ chartName: chartDetails.chartName });
            }
            else {
                this.setState({ chartName: '' });
            }

        } else {
            let propertyName = "Property TB";
            let propertyId = "-1";
            let pctOwnership = 100.0000;
            for (let i = 0; i < this.props.properties.length; i++) {
                if (this.props.properties[i].propertyID.toString() === value) {
                    propertyName = this.props.properties[i].propertyName + " - " + this.props.properties[i].propertyTrackingID;
                    propertyId = this.props.properties[i].propertyID;
                    pctOwnership = this.props.properties[i].percentageOfOwnership;
                    break;
                }
            }

            this.setState({ loading: true });
            this.props.actions.getMappingsByPeriod(this.props.periodId, value, true).then(() =>
                this.setState({
                    selectedTbName: propertyName,
                    selectedTbId: propertyId,
                    selectedTbPctOwnership: pctOwnership,
                    loading: false
                }, () => this.clearAndSetFilter()));

            //Get chart name based on Property Id
            let chartDetails = this.props.chartMappings.find(m => m.propertyID && m.propertyID.toString() === value);
            if (chartDetails) {
                this.setState({ chartName: chartDetails.chartName });
            }
            else {
                this.setState({ chartName: '' });
            }
        }
    }

    clearAndSetFilter() {
        if (this.state.tbMode === 2) {

            let propertyId = this.props.properties[0].propertyID;
            let pctOwnership = this.props.properties[0].percentageOfOwnership;
            this.setState({ selectedTbId: propertyId, selectedTbPctOwnership: pctOwnership });
        }
        this.setState({ tbMappings: [] }, () => this.filterTbMappings());
    }

    // Filters tbMappings based on selectedTbId
    filterTbMappings() {
        const tbMappings = JSON.parse(JSON.stringify(this.props.periodMappings));
        this.setState({ tbMappings });
        return;
    }

    handleDownloadAll() {
        actions.downloadPeriodMappingsFile(this.props.periodId);
    }

    handleCancelDelete() {
        this.setState({ confirmAction: null });
    }

    onConfirmDelete() {
        if (this.state.confirmAction) {
            this.state.confirmAction();
            this.setState({ confirmAction: null });
        }
    }

    handleDeleteAll() {
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return actions.deletePeriodMappings(this.props.periodId).then(() => {
                    this.props.commonActions.endTask();
                    return this.deleteAllTrialBalances();
                }).catch(error => this.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    deleteAllTrialBalances() {
        this.props.commonActions.beginTask();
        actions.deleteTrialBalance(this.props.periodId)
            .then(() => {
                this.props.commonActions.endTask();
                this.props.actions.getLastTrialBalanceByPeriod(this.props.periodId, "reit");
                this.props.actions.getLastPropertyTrialBalanceByPeriod(this.props.periodId, this.state.propertyId);
                if (this.props.period.trialBalanceModeID === 2) {
                    this.props.actions.getMappingsByPeriod(this.props.periodId);
                } else {
                    this.props.actions.getMappingsByPeriod(this.props.periodId, null, true);
                }

            }).catch(error => this.props.commonActions.dispatchErrorAndEndTask(error));
    }

    toggleShowSyncREITTestingAttributesModal() {
        this.setState({
            showSyncREITTestingAttributesModal: !this.state.showSyncREITTestingAttributesModal
        }, () => store.dispatch(reset('synchREITTestingAttribute')));
    }


    handleSyncREITTestsingAttributeAction() {

        let syncREITId = this.state.selectedTbId < 1 ? this.state.reit.reitid : null;
        let syncPropertyId = this.state.selectedTbId < 1 ? null : this.state.selectedTbId;
        this.setState({ loading: true });

        if (this.props.syncREITTestingAttributesFormValues && this.props.syncREITTestingAttributesFormValues.synchType === "1") {
            //Synch REIT Testing Attributes for specific REIT or Property

            this.props
                .chartAssignmentActions
                .syncREITTestsingAttributesForREITorProperty(this.props.periodId, syncREITId, syncPropertyId)
                .then(() => {
                    if (syncREITId && syncREITId > 0) {
                        this.props.actions.getMappingsByPeriod(this.props.periodId, null, true).then(() =>
                            this.setState(() => this.clearAndSetFilter()));
                    }
                    else if (syncPropertyId && syncPropertyId > 0) {

                        this.props.actions.getMappingsByPeriod(this.props.periodId, syncPropertyId, true).then(() =>
                            this.setState(() => this.clearAndSetFilter()));
                    }
                });
        }
        else if (this.props.syncREITTestingAttributesFormValues && this.props.syncREITTestingAttributesFormValues.synchType === "2") {
            //Synch REIT Testing Attributes for specific REIT and all Properties of Report Period Id

            this.props
                .chartAssignmentActions
                .syncREITTestsingAttributesByReportPeriodId(this.props.periodId)
                .then(() => {
                    if (syncREITId && syncREITId > 0) {
                        this.props.actions.getMappingsByPeriod(this.props.periodId, null, true).then(() =>
                            this.setState(() => this.clearAndSetFilter()));
                    }
                    else if (syncPropertyId && syncPropertyId > 0) {

                        this.props.actions.getMappingsByPeriod(this.props.periodId, syncPropertyId, true).then(() =>
                            this.setState(() => this.clearAndSetFilter()));
                    }
                });
        }

        this.setState({ loading: false });
        this.toggleShowSyncREITTestingAttributesModal();
    }

    render() {
        return (
            <MappingsList
                period={this.props.period}
                properties={this.props.properties}
                property={this.props.property}
                formProperty={this.state.formProperty}
                formMapping={this.state.formMapping}
                showForm={this.state.showForm}
                handleCloseForm={this.closeForm}
                handleNewButtonClick={this.handleNewButtonClick}
                handleSubmit={this.submitForm}
                formPurpose={this.state.formPurpose}
                handleEditButtonClick={this.handleEditButtonClick}
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                showSuccess={this.props.showSuccess && !this.state.hideSuccessMessage}
                canEdit={this.state.canEdit}
                handleDismissErrorMessage={this.handleDismissErrorMessage}
                handleDismissSuccessMessage={this.handleDismissSuccessMessage}
                periodPropertyCount={this.state.periodPropertyCount}
                hideSuccessMessage={this.state.hideSuccessMessage}
                tbMappings={this.state.tbMappings}
                selectedTbName={this.state.selectedTbName}
                trialBalancePurposes={this.trialBalancePurposes}
                handleTrialBalancePurposeDropdownSelected={this.handleTrialBalancePurposeDropdownSelected}
                handleDownloadAll={this.handleDownloadAll}
                handleDeleteAll={this.handleDeleteAll}
                confirmAction={this.state.confirmAction}
                handleCancelDelete={this.handleCancelDelete}
                handleConfirmDelete={this.onConfirmDelete}
                submittingMapping={this.state.submittingMapping}
                tbMode={this.state.tbMode}
                selectedTbPctOwnership={this.state.selectedTbPctOwnership}
                loading={this.state.loading}
                chartName={this.state.chartName}
                selectedTbId={this.state.selectedTbId}
                periodId={this.props.periodId}
                reitId={this.state.reit && this.state.reit.reitid}
                clientId={this.props.clientId}
                lookupTypes={this.props.lookupTypes}
                showSyncREITTestingAttributesModal={this.state.showSyncREITTestingAttributesModal}
                toggleShowSyncREITTestingAttributesModal={this.toggleShowSyncREITTestingAttributesModal}
                handleSyncREITTestsingAttributeAction={this.handleSyncREITTestsingAttributeAction}
                syncREITTestingAttributesFormValues={this.props.syncREITTestingAttributesFormValues}
                syncInitialValue={this.state.syncInitialValue}

            />
        );
    }
}

MappingsPage.propTypes = {
    reitId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    periodId: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    period: PropTypes.object,
    properties: PropTypes.array,
    property: PropTypes.object,
    periodMappings: PropTypes.array,
    periodMappingsBalances: PropTypes.array,
    mapping: PropTypes.object,
    showSuccess: PropTypes.bool,
    chartOfAccounts: PropTypes.array,
    chartMappings: PropTypes.array,
    propertyActions: PropTypes.object.isRequired,
    periodActions: PropTypes.object.isRequired,
    chartAssignmentActions: PropTypes.object.isRequired,
    commonActions: PropTypes.object.isRequired,
    syncREITTestingAttributesFormValues: PropTypes.object,
    lookupTypes: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    const searchParams = new URLSearchParams(ownProps.router.location.search);
    const showSuccess = searchParams.get('showSuccess');

    return {
        reitId: Number.parseInt(ownProps.router.params.reitId),
        clientId: Number.parseInt(ownProps.router.params.clientId),
        periodId: Number.parseInt(ownProps.router.params.periodId),
        period: state.period,
        properties: state.properties,
        periodMappings: state.periodMappings,
        periodMappingsBalances: state.periodMappingsBalances,
        currentUserAuthorizations: state.currentUserAuthorizations,
        isSysAdmin: Array.isArray(state.currentUserAuthorizations) && authActions.isSystemAdministrator(state.currentUserAuthorizations),
        mapping: state.mapping,
        showSuccess: showSuccess,
        chartOfAccounts: state.chartOfAccounts,
        chartMappings: state.chartMappings,
        syncREITTestingAttributesFormValues: getFormValues('synchREITTestingAttribute')(state),
        lookupTypes: state.lookupTypes
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        propertyActions: bindActionCreators(propertyActions, dispatch),
        periodActions: bindActionCreators(PeriodActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        chartAssignmentActions: bindActionCreators(chartAssignmentActions, dispatch),
        lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MappingsPage));