import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from "react";
import {
    MotifPagination,
    MotifPaginationSelect,
    MotifPaginationSelectItem,
    MotifTable,
} from "@ey-xd/motif-react";

import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate.js";
import DataRetentionManagementReitCheckboxCellRenderer from "./customRenderers/DataRetentionManagementReitCheckboxCellRenderer";
import DataRetentionManagementReitCheckboxHeaderRenderer from "./customRenderers/DataRetentionManagementReitCheckboxHeaderRenderer";
import { getPreviousFridayOrGivenDate } from "../../scripts/dateHelpers";
import * as formatters from "../../scripts/formFormatters";
import { DELETION_STATUSES } from "../../constants/other";

const DataRetentionManagementTable = ({
    dataRetentionManagementData,
    gridColumnState,
    handleGridChanges,
    handleGridColumnStateChange,
    refreshGriddataRetentionManagementData
}) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    const onHeaderCheckboxChange = (isHeaderCheckboxSelected) => {
        let updatedData = rowDataRef.current;
        if (gridRef && gridRef.current && gridRef.current.api) {

            const statusesToCheck = [
                DELETION_STATUSES.IN_PROGRESS,
                DELETION_STATUSES.DELETED,
                DELETION_STATUSES.FAILED
            ];
            // Select checkbox for filtered rows only
            gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
                // Invert the selection status for each row

                if (rowNode.level === 0) {

                    updatedData = updatedData.map(newUd => {
                        const { stage2DeletionStatus, stage3DeletionStatus } = newUd;
                        const isStatusMatched = statusesToCheck.includes(stage2DeletionStatus) || statusesToCheck.includes(stage3DeletionStatus);
                        return {
                            ...newUd,
                            reitSelection: !isStatusMatched ? !isHeaderCheckboxSelected : newUd.reitSelection,
                        };
                    });
                }
            });

            setRowData(updatedData);
            gridRef.current.api.refreshHeader();
        }
    };


    const onCellCheckboxChange = (reportPeriodID, rowNode) => {
        const updatedData = rowDataRef.current.map((newUd) => {
            if (newUd.reportPeriodID === reportPeriodID) {
                return {
                    ...newUd,
                    reitSelection: !newUd.reitSelection,
                };
            }
            return newUd;
        });

        setRowData(updatedData);
        gridRef.current.api.refreshHeader();
    };

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    // MM:DD:YYYY HH:MM AM/PM
    const dateFormat1 = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    // MM:DD:YYYY
    const dateFormat2 = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    };

    const getColumnDefs = () => {
        return [
            {
                field: "reitSelection",
                headerName: "",
                suppressMenu: true,
                headerComponent: DataRetentionManagementReitCheckboxHeaderRenderer,
                headerComponentParams: {
                    onHeaderCheckboxChange: onHeaderCheckboxChange,
                },
                cellRenderer: DataRetentionManagementReitCheckboxCellRenderer,
                cellRendererParams: { onCellCheckboxChange: onCellCheckboxChange },
                minWidth: 70,
                spanHeaderHeight: true,
                resizable: true,
                cellStyle: params => {
                    const statusesToCheck = ['Failed', 'Deleted', 'In Progress'];
                    const { stage2DeletionStatus, stage3DeletionStatus } = params.data;

                    const isStatusMatched = statusesToCheck.includes(stage2DeletionStatus) || statusesToCheck.includes(stage3DeletionStatus);

                    return isStatusMatched ? { 'pointer-events': 'none', opacity: '0.3' } : '';
                },
            },
            {
                field: "reitName",
                headerName: "REIT",
                sortable: true,
                showRowGroup: false,
                rowGroup: false,
                minWidth: 300,
                valueGetter: (params) => {
                    return params.data && params.data.reitName;
                },
                filterValueGetter: (params) => {
                    return params.data && params.data.reitName;
                },
                headerComponentParams: { template: CustomHeaderTemplate("reitName") },
                spanHeaderHeight: true,
                resizable: true,
            },
            {
                headerName: "Report Period",
                field: "reportPeriodDescription",
                sortable: true,
                showRowGroup: false,
                rowGroup: false,
                minWidth: 250,
                valueGetter: (params) => {
                    return params.data && params.data.reportPeriodDescription;
                },
                filterValueGetter: (params) => {
                    return params.data && params.data.reportPeriodDescription;
                },
                headerComponentParams: { template: CustomHeaderTemplate("reportPeriodDescription") },
                spanHeaderHeight: true,
                resizable: true,
            },
            {
                headerName: "Checklist Attachments and Other Period Level Files",
                marryChildren: true,
                children: [
                    {
                        field: "stage2ScheduledDeletionDate",
                        headerName: "Scheduled Deletion Date",
                        sortable: true,
                        showRowGroup: false,
                        hide: false,
                        rowGroup: false,
                        minWidth: 150,
                        valueGetter: (params) => {
                            const dateValue = params.data && params.data ? params.data.stage2ScheduledDeletionDate : null
                            return dateValue ? formatters.formatDate(getPreviousFridayOrGivenDate(dateValue)) : null;
                        },
                        filterValueGetter: (params) => {
                            const dateValue = params.data && params.data ? params.data.stage2ScheduledDeletionDate : null
                            return dateValue ? formatters.formatDate(getPreviousFridayOrGivenDate(dateValue)) : null;
                        },

                        headerComponentParams: {
                            template: CustomHeaderTemplate("stage2ScheduledDeletionDate"),
                        },
                        cellStyle: { textAlign: "center" },
                        resizable: true,
                    },
                    {
                        field: "stage2ActualDeletionDate",
                        headerName: "Actual Deletion Date",
                        sortable: true,
                        showRowGroup: false,
                        hide: false,
                        rowGroup: false,
                        minWidth: 160,
                        valueGetter: (params) => {
                            const dateValue = params.data && params.data.stage2ActualDeletionDate ? params.data.stage2ActualDeletionDate : null;
                            return dateValue ? formatters.formatDate(dateValue) : null;
                        },
                        filterValueGetter: (params) => {
                            const dateValue = params.data && params.data.stage2ActualDeletionDate ? params.data.stage2ActualDeletionDate : null;
                            return dateValue ? formatters.formatDate(dateValue) : null;
                        },
                        headerComponentParams: {
                            template: CustomHeaderTemplate("stage2ActualDeletionDate"),
                        },
                        cellStyle: { textAlign: "center" },
                        resizable: true,
                    },
                    {
                        field: "stage2DeletionStatus",
                        headerName: "Deletion Status",
                        sortable: true,
                        showRowGroup: false,
                        hide: false,
                        rowGroup: false,
                        minWidth: 160,
                        valueGetter: (params) => {
                            return params.data && params.data.stage2DeletionStatus;
                        },
                        filterValueGetter: (params) => {
                            return params.data && params.data.stage2DeletionStatus;
                        },
                        headerComponentParams: {
                            template: CustomHeaderTemplate("stage2DeletionStatus"),
                        },
                        cellStyle: { textAlign: "center" },
                        resizable: true,
                    },
                ],
            },
            {
                headerName: "Checklist Response, Trial Balances and Period Test Results",
                marryChildren: true,
                children: [
                    {
                        field: "stage3ScheduledDeletionDate",
                        headerName: "Scheduled Deletion Date",
                        sortable: true,
                        showRowGroup: false,
                        hide: false,
                        rowGroup: false,
                        minWidth: 150,
                        valueGetter: (params) => {
                            const dateValue = params.data && params.data ? params.data.stage3ScheduledDeletionDate : null
                            return dateValue ? formatters.formatDate(getPreviousFridayOrGivenDate(dateValue)) : null;
                        },
                        filterValueGetter: (params) => {
                            const dateValue = params.data && params.data ? params.data.stage3ScheduledDeletionDate : null
                            return dateValue ? formatters.formatDate(getPreviousFridayOrGivenDate(dateValue)) : null;
                        },

                        headerComponentParams: {
                            template: CustomHeaderTemplate("stage3ScheduledDeletionDate"),
                        },
                        cellStyle: { textAlign: "center" },
                        resizable: true,
                    },
                    {
                        field: "stage3ActualDeletionDate",
                        headerName: "Actual Deletion Date",
                        sortable: true,
                        showRowGroup: false,
                        hide: false,
                        rowGroup: false,
                        minWidth: 160,
                        valueGetter: (params) => {
                            const dateValue = params.data && params.data.stage3ActualDeletionDate ? params.data.stage3ActualDeletionDate : null;
                            return dateValue ? formatters.formatDate(dateValue) : null;
                        },
                        filterValueGetter: (params) => {
                            const dateValue = params.data && params.data.stage3ActualDeletionDate ? params.data.stage3ActualDeletionDate : null;
                            return dateValue ? formatters.formatDate(dateValue) : null;
                        },
                        headerComponentParams: {
                            template: CustomHeaderTemplate("stage3ActualDeletionDate"),
                        },
                        cellStyle: { textAlign: "center" },
                        resizable: true,
                    },
                    {
                        field: "stage3DeletionStatus",
                        headerName: "Deletion Status",
                        sortable: true,
                        showRowGroup: false,
                        hide: false,
                        rowGroup: false,
                        minWidth: 160,
                        valueGetter: (params) => {
                            return params.data && params.data.stage3DeletionStatus;
                        },
                        filterValueGetter: (params) => {
                            return params.data && params.data.stage3DeletionStatus;
                        },
                        headerComponentParams: {
                            template: CustomHeaderTemplate("stage3DeletionStatus"),
                        },
                        cellStyle: { textAlign: "center" },
                        resizable: true,
                    },


                ],
            },
            {
                field: "approvedBy",
                headerName: "Approved By",
                sortable: true,
                showRowGroup: false,
                hide: false,
                rowGroup: false,
                minWidth: 250,
                valueGetter: (params) => {
                    return params.data && params.data.approvedBy;
                },
                filterValueGetter: (params) => {
                    return params.data && params.data.approvedBy;
                },
                headerComponentParams: { template: CustomHeaderTemplate("approvedBy") },
                cellStyle: { textAlign: "center" },
                resizable: true,
            },
            {
                field: "approvedDateTime",
                headerName: "Approved Date",
                sortable: true,
                showRowGroup: false,
                hide: false,
                rowGroup: false,
                minWidth: 250,
                valueGetter: params => {
                    const dateValue = params.data && params.data ? params.data.approvedDateTime : null;
                    if (dateValue) {
                        const date = new Date(dateValue);
                        // use toLocaleString with undefined value to get the local time zone settings
                        const formattedDate = (new Date(date + 'Z')).toLocaleString(undefined, dateFormat1);
                        return `${formattedDate}`;
                    }
                    return '';
                },
                headerComponentParams: {
                    template: CustomHeaderTemplate("approvedDateTime"),
                },
                cellStyle: { textAlign: "center" },
                resizable: true,
            },
        ];
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }

        // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
        if (type.indexOf("filterChanged") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);
        }

        if (type.indexOf("rowDataUpdated") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);
        }
    };

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem(
            "REITSuiteDataRetentionManagementColumnState"
        );
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }

        // Set the page size and total pages on the first load
        handleItemsPerPageChange(100); // Setting it 100 to make sure the default number of items on load is always set to 100
    };

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        let filteredRowData = [];
        if (gridRef && gridRef.current && gridRef.current.api) {
            setTimeout(function () { // Included Settimeout function to assign the total pages after getting the total pages from grid
                let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
                setTotalPages(calcTotalPages);
                if (currentPage > calcTotalPages) {
                    if (calcTotalPages == 0) {
                        setTimeout(function () {
                            setCurrentPage(calcTotalPages);
                        }, 0);
                    } else {
                        setCurrentPage(calcTotalPages);
                    }
                }
                const gridCurrentPage =
                    gridRef.current.api.paginationGetCurrentPage() + 1; // Adding 1 since it returns zero based index
                if (currentPage < gridCurrentPage && calcTotalPages != 0) {
                    setCurrentPage(gridCurrentPage);
                }

                // Show the expected value as 1 for current page 
                if (currentPage <= 1 && gridCurrentPage == 1 && calcTotalPages != 0) {
                    setCurrentPage(gridCurrentPage);
                }
            }, 0);

        }
    };

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    };

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {
        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem(
            "REITSuiteDataRetentionManagementColumnState"
        );

        // If the column state details are not exists in local storage then save the changed Column state data
        if (
            currentColumnState &&
            currentColumnState.filter((col) => col.hide == true)?.length > 0 &&
            !localStorageColumnState
        ) {
            saveUserPreferenceDetails();
        } else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (
                columnsStateArray &&
                columnsStateArray.length > 0 &&
                currentColumnState &&
                currentColumnState.length > 0 &&
                columnsStateArray.filter((col) => col.hide == true)?.length !=
                currentColumnState.filter((col) => col.hide == true)?.length
            ) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef &&
            gridRef.current &&
            gridRef.current.columnApi &&
            gridRef.current.columnApi.autoSizeAllColumns();
    };

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {
        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem(
                "REITSuiteDataRetentionManagementColumnState",
                JSON.stringify(columnState)
            );
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {
        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            setTimeout(function () { // Included Settimeout function to assign the total pages after getting the total pages from grid
                let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
                setTotalPages(calcTotalPages);
                //If total page is zero then set the current page as zero
                if (calcTotalPages == 0) {
                    setCurrentPage(calcTotalPages);
                }
            }, 0);
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    useEffect(() => {
        handleGridChanges(
            rowData,
            gridRef && gridRef.current ? gridRef.current.api : null
        );
    }, [rowData]);

    useEffect(() => {
        setRowData(dataRetentionManagementData);

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.redrawRows();
            gridRef.current.api.refreshHeader();
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            setTimeout(function () { // Included Settimeout function to assign the total pages after getting the total pages from grid
                let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
                setTotalPages(calcTotalPages);

            }, 0);
        }
    }, [dataRetentionManagementData]);

    useEffect(() => {
        //Reset Grid to default column state
        //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
        var localStorageColumnState = localStorage.getItem(
            "REITSuiteDataRetentionManagementColumnState"
        );
        if (
            !localStorageColumnState &&
            gridRef &&
            gridRef.current &&
            gridRef.current.api
        ) {
            gridRef.current.api.setColumnDefs([]);
            gridRef.current.api.setColumnDefs(getColumnDefs());
        }
    }, [gridColumnState]);

    useEffect(() => {
        // Refresh Grid         
        const gridApi = gridRef && gridRef.current && gridRef.current.api;
        if (gridApi) {
            gridApi.refreshCells();
            gridApi.deselectAll();
        }

    }, [refreshGriddataRetentionManagementData]);

    const rowHeight = 76;
    const headerHeight = 45;
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default


    return (
        <>
            <div>
                <div className="row mb-10 margin-top-5">
                    {dataRetentionManagementData &&
                        dataRetentionManagementData.length > 0 && (
                            <div className="col mt-3 data-retention-management-grid">
                                <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                                    <MotifTable zebra={true} compact={true}
                                        rowData={rowData}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        accentedSort={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        onGridReady={handleGridReady}
                                        onFirstDataRendered={handleFirstDataRendered}
                                        ref={gridRef}
                                        pagination={true}
                                        suppressPaginationPanel={true}
                                        paginationPageSize="100"
                                        groupDisplayType={"custom"}
                                        groupSelectsChildren={true}
                                        groupDefaultExpanded={0}
                                        onFilterChanged={handleFilterChanged}
                                        data-testid="divDataRetentionManagementGrid"
                                    />
                                    <div className="mt-3">
                                        <span className="right mb-3">
                                            <MotifPagination
                                                currentPage={currentPage}
                                                onPageChange={handlePageChange}
                                                min={1}
                                                max={totalPages}
                                            >
                                                <MotifPaginationSelect data-testid="itemsPerPage">
                                                    <MotifPaginationSelectItem
                                                        onClick={() => handleItemsPerPageChange(50)}
                                                    >
                                                        Show 50
                                                    </MotifPaginationSelectItem>
                                                    <MotifPaginationSelectItem
                                                        selected
                                                        onClick={() => handleItemsPerPageChange(100)}
                                                    >
                                                        Show 100
                                                    </MotifPaginationSelectItem>
                                                    <MotifPaginationSelectItem
                                                        onClick={() => handleItemsPerPageChange(150)}
                                                    >
                                                        Show 150
                                                    </MotifPaginationSelectItem>
                                                    <MotifPaginationSelectItem
                                                        onClick={() => handleItemsPerPageChange(200)}
                                                    >
                                                        Show 200
                                                    </MotifPaginationSelectItem>
                                                </MotifPaginationSelect>
                                            </MotifPagination>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};

export default DataRetentionManagementTable;
