import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Icon } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { Dropdown as BootstrapDropdown, ButtonGroup } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { download } from '../../scripts/fileHelpers';
import { fetchFromTrialBalanceApi } from '../../actions/fetchFromApi';
import { MotifFormField, MotifSearch, MotifLabel, MotifToggleSwitch, MotifDropdown, MotifDropdownItem, MotifButton,  MotifIcon, MotifBadge } from '@ey-xd/motif-react';
import { MotifIconButton } from '@ey-xd/motif-react';
import { IconoirNavArrowDown, IconoirEditPencil } from '@ey-xd/motif-icon';
import { addAttribute } from "../../common/Attribute";

const ChartOfAccountsManagerHeader = ({
    clientId,
    isEditModeEnabled,
    chartOfAccounts,
    chartOfAccountsFormValues,
    selectedChart,
    showCreateClientAccountModal,
    showUploadChartModal,
    lookupTypes,
    handleAddClientAccount,
    handleOnEditToggle,
    pristine,
    submitting,
    invalid,
    searchBoxText,
    handleSearchBox,
    handleClearSearchText,
    onSubmit,
    handleSubmit,
    onCancel,
    clearDuplicateChartOfAccountErrorMessage,
    toggleShowCreateTemplateModal,
    toggleEditNameModal,
    toggleShowCreateClientAccountModal,
    toggleShowDeleteTemplateModal,
    toggleShowChangeDefaultChartModal,
    toggleShowUploadChartModal,
    editorUsed,
    handleChartSelection
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const [managementDropdownOpen, setManagementDropdownOpen] = useState(false);
    const preventSubmitOnEnter = (e) => {
        if (e.key === 'Enter') {
            //Avoid preventing key press for buttons
            if (e.target && e.target.type !== 'button') {
                e.preventDefault();
            }
        }
    };

    function downloadChartOfAcconts() {
        setManagementDropdownOpen(false);
        fetchFromTrialBalanceApi(`/chartOfAccounts/download?chartId=${chartOfAccountsFormValues.chartID}`)
            .then(function (response) {
                return response.blob().then(function (blob) {
                    const contentDisposition =
                        response.headers.get("Content-Disposition");

                    const filename =
                        contentDisposition.split(';')[1].split('=')[1].trim();

                    download(blob, filename);
                });
            })
            .catch(e => console.error(e));
    }

    const chartManagementOptions = [
        { key: "1", value: "1", text: "Download Chart of Accounts", onClickHandle: downloadChartOfAcconts },
        { key: "2", value: "2", text: "Upload Chart of Accounts", onClickHandle: () => { setManagementDropdownOpen(false); toggleShowUploadChartModal();} },
        { key: "3", value: "3", text: "Add Account", onClickHandle: () => { setManagementDropdownOpen(false); toggleShowCreateClientAccountModal();} },
        { key: "4", value: "4", text: "Edit Accounts", onClickHandle: () => { setManagementDropdownOpen(false); handleOnEditToggle();} }
    ];

    if (!chartOfAccountsFormValues ||
        typeof chartOfAccountsFormValues.chartID !== 'number' ||
        chartOfAccountsFormValues.chartID < 0) {
        chartManagementOptions.shift();
    }

    const chartOfAccountsOptions =
        chartOfAccounts.charts
            .map(t => {
                return { key: t.chartID, value: t.chartID, text: `${t.isDefault ? '(Default) ' : ''}${t.chartName}` }
            });

    const chart = chartOfAccountsFormValues
        && chartOfAccountsFormValues.chartID
        && chartOfAccountsFormValues.chartID !== -1 ?
        chartOfAccounts.charts.find(t => t.chartID == chartOfAccountsFormValues.chartID) : null;

    //Add set default option based on condition
    if (chart && !chart.isDefault) {
        chartManagementOptions.push({ key: "5", value: "5", text: "Set as Default for Client", onClickHandle: toggleShowChangeDefaultChartModal });
    }

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit(onSubmit)} onKeyPress={preventSubmitOnEnter}>
                <div style={{ minHeight: '24px' }}></div>
                <div className="row">
                    <div className="col">To customize a chart of accounts, choose one of the following options to view the chart details.</div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <div className="d-flex flex-row align-items-center">                            
                            <MotifButton variant="primary-alt" type="button" onClick={() => toggleShowCreateTemplateModal('create')}>Create New Chart</MotifButton>
                            {chartOfAccounts.charts && chartOfAccounts.charts.length > 0 &&
                                <React.Fragment>
                                    <h6 className="ml-3 mr-3">OR</h6>
                                    <Field
                                        name="chartID"
                                        label="Select a Chart"
                                        onChange={handleChartSelection}
                                        options={chartOfAccountsOptions}
                                        aria-label={"Select a chart"}
                                        component={formHelpers.SingleSelectComponent}
                                        renderUntilValueSet={true}
                                        renderUnitOptionsSet={true}
                                        style={{ minWidth: '275px', marginBottom:'0px' }} />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </Form >
                {chartOfAccountsFormValues && chartOfAccountsFormValues.chartID !== -1 && chart &&
                    <React.Fragment>
                        <div className="ui divider mt-4 mb-2"></div>
                        <div className="row align-items-center margin-top-5">
                            <div className="col d-flex align-items-center">
                                <h5 className='d-inline mr-1'>{chart.chartName}</h5>
                                <MotifIconButton aria-label="Edit Chart of Accounts" type="button"
                                    onClick={() => toggleEditNameModal()} disabled={isEditModeEnabled} >
                                    <MotifIcon iconFunction={IconoirEditPencil} size='24' fill='none' title="Edit Chart of Accounts" />
                                </MotifIconButton> 
                                                              
                                {chart.isDefault &&
                                    <MotifBadge variant="alt" style={{ marginLeft: '5px', minWidth: 'auto'  }} value="Default" 
                                        data-testid="badgeDefaultChart" />
                                }
                            </div>
                            <div className="col pt-2">
                                <MotifFormField>
                                    <MotifSearch
                                        type="text"
                                        items={[]}
                                        onChange={handleSearchBox}
                                        onSelect={handleClearSearchText}
                                        value={searchBoxText}
                                        name="chartFilter"
                                        title="Search chart"
                                        aria-label="Search Chart"                           
                                        placeholder="Search Chart"
                                        searchButtonProps={{tabIndex:"-1"}} />
                                </MotifFormField>
                            </div>
                            <div className="mr-3 mb-2 d-flex flex-row-reverse align-items-center">
                                <MotifDropdown
                                    open={managementDropdownOpen}
                                    placement="bottom-end"
                                    id={'chart-management'}
                                    aria-labelledby={'client-management-trigger'}
                                    handleClickOutside={() => setManagementDropdownOpen(false)}
                                    trigger={
                                        <MotifButton
                                            disabled={isEditModeEnabled}
                                            type="button"
                                            variant="primary-alt"
                                            onClick={() => setManagementDropdownOpen(!managementDropdownOpen)}
                                            aria-controls={'chart-management'}
                                            id={'chart-management-trigger'}
                                            aria-expanded={managementDropdownOpen}
                                            aria-label="Chart management dropdown"
                                        >
                                            Chart Management <MotifIcon iconFunction={IconoirNavArrowDown} size='24' fill='none' />
                                        </MotifButton>
                                    }
                                >
                                    {chartManagementOptions.map((cmoption, index) => 
                                        <MotifDropdownItem 
                                            onClick={cmoption.onClickHandle} 
                                            key={cmoption.key} 
                                            disabled={(cmoption.key === "4" && selectedChart.chartMappings.length == 0)}>{cmoption.text}
                                        </MotifDropdownItem>
                                    )}
                                </MotifDropdown>
                            </div>
                        </div>

                    </React.Fragment>}
        </React.Fragment>
    );
};

ChartOfAccountsManagerHeader.propTypes = {
    lookupTypes: PropTypes.object,    
    handleClearSearchText: PropTypes.func,
};

export default reduxForm({ form: "chartOfAccountsForm" })(ChartOfAccountsManagerHeader);